export default {
    Order: {
        Title: {
            List: '订单',
            Detail: '订单详情',
            Dialog: '订单 {no}',
        },
        ListTable: {
            TH1: '订单号',
            TH2: '类型/产品',
            TH3: '创建时间',
            TH4: '支付时间',
            TH5: '订单金额',
            TH6: '应付金额',
            TH7: '状态',
            TH8: '操作',
            LinkDetail: '详情',
            LinkPay: '付款',
            BtnCancel: '作废'
        },
        Detail: {
            H1: '订单编号',
            H2: '订单类型',
            H3: '创建时间',
            H4: '支付时间',
            H5: '订单状态',
            H6: '订单金额',
            H7: '应付金额',
            TH1: '产品',
            TH2: '计费类型',
            TH3: '购买时长',
            TH4: '套餐及服务',
            TH5: '数量',
            TH6: '金额',
            BtnPay: '立即付款',
            BtnCancel: '作废订单',
            LinkToApp: '前往应用控制台',
        },
        Hint: {
            S1: '还没有订单',
            S2: '确定作废 ？',
            S3: '订单作废成功',
        }
    }
}